#edit-pin-form {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  width: 95vw;
  height: 800px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

#modal-content .edit-pin > i {
  display: none;
}

#edit-pin-form-title {
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  padding: 10px 0;
}

#show-pin-board-dropdown-btn.edit-pin-boards {
  width: calc(100% - 33px);
  border-radius: 14px;
  border: 2px solid lightgray;
  position: relative;
}

#edit-pin-form-bottom-container {
  display: flex;
}

#edit-pin-form-left {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit-pin-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; 
  align-items: center;
  padding: 15px;
  margin: 20px 0;
}

.edit-pin-row-label {
  font-weight: 300;
  font-size: 14px;
  width: 142px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.edit-pin-row-field {
  max-width: 520px;
  width: 30vw;
}

.edit-pin-text-input {
  width: 100%;
  box-sizing: border-box;
  min-height: 48px;
  border-radius: 14px;
  border: 2px solid lightgray;
  padding: 13px;
  font-size: 16px;
  color: #444444;
}

.edit-pin-text-input:hover {
  border: 2px solid #777777;
}

.edit-pin-text-input:focus {
  outline: none;
  border: 2px solid #968bc6;
}

textarea.edit-pin-text-input {
  padding: 13px;
  min-height: 100px; 
  line-height: 1.5em;
}

#edit-pin-underline {
  height: 1px;
  width: 100%;
  background-color: rgb(208, 208, 208);
}

#edit-pin-form-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

#edit-pin-img {
  width: 236px;
  border-radius: 8px;
  margin: 15px;
}

#edit-pin-form-bottom-bar {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85px;
  padding: 24px;
  background-color: white;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  box-shadow: 0 -2px 8px rgba(0,0,0,0.12);
}

#edit-pin-bottom-btn-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.edit-pin-btn {
  padding: 12px;
  border-radius: 24px;
  cursor: pointer;
}

.edit-pin-btn.cancel, .edit-pin-btn.delete {
  margin-right: 16px;
  background-color: rgb(238, 238, 238);
}

.edit-pin-btn.cancel:hover, .edit-pin-btn.delete:hover {
  background-color: rgb(208, 208, 208);
}

.edit-pin-btn.save {
  background-color: rgb(85, 15, 159);
  color: white;
}

.edit-pin-btn.save:hover {
  background-color: rgb(56, 10, 106);
}

#saved-msg-container.edit-pin {
  top: 0px;
  left: calc(50% - 132px);
}