#splash-bg-container {
  display: flex;
  justify-content: center;
  scroll-margin: 0;
}

#splash-page-gradient {
  position: absolute;
  top: 80px;
  left: 0;
  height: 200px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 70%);
}

#splash-text-container {
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 64px;
  font-weight: 600;
}

#splash-top-text {
  margin-bottom: 8px;
}

#splash-bottom-text {
  color: rgb(85, 15, 159);
}

#splash-grid-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1750px;
  height: 100vh;
  position: absolute;
  bottom: 0vh;
  margin: 0 auto;
  overflow: hidden;
}

.splash-grid-col {
  width: 234px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.splash-grid-col > img {
  width: 234px;
  border-radius: 16px;
  margin-top: 16px;
  position: relative;
}

.splash-grid-col.opaque-drip {
  opacity: 0;
}

.col-1 {
  position: relative;
  top: -88vh;
  height: 88vh;
  transition: transform 2.1s ease-out 0.9s, opacity 1s ease-out 0s;
}

.col-1.drip-col {
  transform: translateY(88vh);
}

.col-1.fade-out {
  opacity: 0;
  transform: translateY(-10vh);
}

.col-1.fade-in {
  opacity: 1;
  transform: translateY(10vh);
}

.col-2 {
  position: relative;
  top: -76vh;
  height: 76vh;
  transition: transform 1.3s ease-out 1.1s, opacity 1s ease-out 0.2s;
}

.col-2.drip-col {
  transform: translateY(76vh);
}

.col-3 {
  position: relative;
  top: -64vh;
  height: 64vh;
  transition: transform 1.9s ease-out 1.3s, opacity 1s ease-out 0.4s;
}

.col-3.drip-col {
  transform: translateY(64vh);
}

.col-4 {
  position: relative;
  top: -54vh;
  height: 54vh;
  transition: transform 2.4s ease-out 1.5s, opacity 1s ease-out 0.6s;
}

.col-4.drip-col {
  transform: translateY(54vh);
}

.col-5 {
  position: relative;
  top: -64vh;
  height: 64vh;
  transition: transform 2.7s ease-out 1.7s, opacity 1s ease-out 0.8s;
}

.col-5.drip-col {
  transform: translateY(64vh);
}

.col-6 {
  position: relative;
  top: -76vh;
  height: 76vh;
  transition: transform 1.7s ease-out 1.9s, opacity 1s ease-out 1s;
}

.col-6.drip-col {
  transform: translateY(76vh);
}

.col-7 {
  position: relative;
  top: -88vh;
  height: 88vh;
  transition: transform 2.1s ease-out 2.1s, opacity 1s ease-out 1.2s;
}

.col-7.drip-col {
  transform: translateY(88vh);
}