#pins-index-page {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 95px;
  margin-bottom: 50px;
}

#pins-index-container {
  max-width: 90%;
  column-count: 7;
  column-gap: 5px;
  margin: 0 auto;
}

.pin-index-item {
  margin: 0 5px 10px 5px;
  padding: 0;
  border-radius: 16px;
}

.pin-index-item.home-pin {
  display: inline-block;
}

.home-pin-title {
  line-height: 1.5em;
  font-weight: 500;
  font-size: 14px;
  padding: 0 8px;
}

.pin-index-item .home-pin-title:hover {
  cursor: zoom-in;
}

.pin-index-item .avatar {
  width: 32px;
  height: 32px;
}

.pin-img-overlay-container {
  position: relative;
}

.pin-overlay {
  background-color: rgba(0, 0, 0);
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100% - 2px);
  border-radius: 16px;
  cursor: zoom-in;
}

.pin-item-top-bar {
  position: absolute;
  top: 0;
  opacity: 1;
  width: calc(88% - 8px);
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: calc(6% + 4px);
  padding-right: calc(6% + 4px);
  border-radius: 16px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.pin-item-top-bar #show-pin-board-dropdown-btn {
  justify-content: flex-end;
  padding: 0;
}

.pin-item-top-bar #show-pin-board-dropdown-btn .board-drop,
.pin-item-top-bar #show-pin-board-dropdown-btn #board-first-option {
  font-size: 16px;
  color: white;
}

#pin-item-save-btn {
  min-width: 35px;
  font-size: 16px;
  padding: 16px;
  border-radius: 24px;
  color: white;
  background-color: rgb(85, 15, 159);
  cursor: pointer;
}

#pin-item-save-btn:hover {
  background-color: rgb(56, 10, 106);
}

#pin-item-save-btn.saved {
  min-width: 45px;
  background-color: black;
}

#pin-item-save-btn.saved:hover {
  background-color: black;
}

.pin-item-top-bar #board-options-menu {
  width: 360px;
  top: 62px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
}

.pin-index-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 16px;
  position: relative;
}

.home-pins-info {
  padding: 8px 6px 16px 6px;
}

.home-pins-info a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.home-pins-info span:hover {
  text-decoration: underline;
}

.home-pins-info a .avatar {
  margin-right: 10px;
}

.home-pins-info #pin-show-creator-initial {
  width: 32px;
  height: 32px;
  font-size: 12px;
}


@media (min-width: 0px) and (max-width: 600px) {
  #pins-index-container {
    column-count: 2;
  }
}

@media (min-width: 600px) and (max-width: 850px) {
  #pins-index-container {
    column-count: 3;
  }
}

@media (min-width: 850px) and (max-width: 1100px) {
  #pins-index-container {
    column-count: 4;
  }
}

@media (min-width: 1100px) and (max-width: 1350px) {
  #pins-index-container {
    column-count: 5;
  }
}

@media (min-width: 1350px) and (max-width: 1600px) {
  #pins-index-container {
    column-count: 6;
  }
}

@media (min-width: 1600px) {
  #pins-index-container {
    column-count: 7;
  }
}