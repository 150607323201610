/* PROFILE COMPONENT */
#profile-user-container {
  position: fixed;
  top: 80px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  z-index: -1;
  overflow-y: scroll;
}

/* USERINFO COMPONENT */
#user-info-container {
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#user-info-initial-holder {
  margin: 4px 0 10px 0;
  height: 120px;
  width: 120px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(238, 238, 238);
}

#user-info-initial {
  font-size: 48px;
  font-weight: 600;
  border-radius: 100%;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  object-fit: cover;
}

#user-info-name-container {
  max-width: 490px;
  margin-bottom: 10px;
  margin-top: 0px;
}

#user-info-name {
  font-size: 34px;
  font-weight: 600;
  word-wrap: break-word;
  text-align: center;
  line-height: 1.2em;
}

#user-info-username-container {
  max-width: 490px;
  margin-bottom: 10px;
}

#user-info-username {
  font-size: 14px;
  font-weight: 300;
  color: rgb(101, 101, 101);
}

#user-info-urlabout-container {
  margin-bottom: 10px;
  text-align: center;
}

#user-info-urlabout-container a {
  font-weight: 600;
  cursor: pointer;
}

#user-info-urlabout-container a:hover {
  text-decoration: underline;
}

#user-info-follow-container {
  margin-bottom: 20px;
}

#user-info-follow {
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}

#user-info-follow.bold-follow {
  font-weight: 600;
}

#edit-profile-btn {
  min-width: 60px;
  min-height: 40px;
  box-sizing: border-box;
  background-color: rgb(238, 238, 238);
  color: #111111;
  cursor: pointer;
  border-radius: 24px;
  padding: 16px 16px 16px 16px;
  border: 0;
  font-size: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

#edit-profile-btn:hover {
  background-color: rgb(215, 215, 215);
}

/* SELECTORBAR COMPONENT */
#selector-bar-container {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

#selector-bar {
  display: flex;
  justify-content: space-between;
  width: 160px;
  font-weight: 500;
  font-size: 16px;
}

#profile-created-tab-container, #profile-saved-tab-container {
  min-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#profile-created-tab-container:hover, 
#profile-saved-tab-container:hover {
  cursor: pointer;
}

#profile-created-tab, #profile-saved-tab {
  position: relative;
  padding: 8px;
  border-radius: 9px;
}

#profile-created-tab.unselected:hover, #profile-saved-tab.unselected:hover {
  background-color: rgb(238, 238, 238);
}

#profile-created-underline {
  width: 58px;
  height: 3px;
  background-color: black;

}

#profile-saved-underline {
  width: 45px;
  height: 3px;
  background-color: black;
}



/* CREATED */
#profile-user-container #pins-index-container {
  margin-bottom: 100px;
}

#profile-user-container #pins-index-bar {
  height: 3vh;
}