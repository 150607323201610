#show-page {
  margin-top: min(90px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
}

#pin-show-container {
  max-width: 1024px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  padding: 24px;
  position: relative;
  cursor: auto;
}

#pin-show-img {
  border-radius: 16px 0 0 16px;
  max-width: 512px;
}

#pin-info-container {
  width: 100%;
  margin: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#pin-show-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

#pin-show-top-bar #ellipsis-btn {
  padding: 12px;
  position: relative;
}

#pin-show-drop {
  position: absolute;
  box-sizing: border-box;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  top: 48px;
  min-width: 180px;
  background-color: white;
  box-shadow: 0 0 8px rgba(0,0,0,0.1);
  padding: 10px;
  border-radius: 16px;
  z-index: 1;
}

.show-pin-drop-option {
  display: block;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
}

.show-pin-drop-option:hover {
  background-color: rgb(238, 238, 238);
}

#show-board-drop-save-btn-holder {
  width: 205px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

#show-pin-board-dropdown-btn {
  width: 100px;
  padding: 16px;
  border-radius: 24px;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
} 

#show-pin-board-dropdown-btn:hover {
  cursor: pointer;
}

#board-first-option:hover {
  cursor: pointer;
  border-color: rgb(238, 238, 238);
}

.dropbtn.board-drop {
  margin-left: 8px;
}

#board-options-menu {
  position: absolute;
  width: 360px;
  padding: 12px;
  border-radius: 16px;
  background-color: white;
  top: 55px;
  left: -90px;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;
}

#board-options-title {
  font-size: 12px;
  font-weight: 300;
  padding: 8px 12px;
  border-radius: 16px;
  cursor: default;
}

#board-options-container {
  overflow-y: scroll;
  max-height: 330px;
  margin-bottom: 8px;
}

.board-dropdown-option {
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.board-dropdown-thumbnail-holder {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: rgb(238, 238, 238);
  margin-right: 8px;
}

.board-dropdown-thumbnail {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;
}

.board-dropdown-info {
  width: 288px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.board-dropdown-name-holder {
  display: flex;
  flex-direction: column;
}

.board-dropdown-name-holder div:nth-child(2) {
  margin-top: 4px;
  color: #666;
  font-weight: 300;
  font-size: 12px;
}

.board-dropdown-option:hover {
  background-color:rgb(238, 238, 238);
}

#show-pin-save-btn {
  padding: 16px 14px;
  border-radius: 24px;
  color: white;
  background-color: rgb(85, 15, 159);
  cursor: pointer;
}

#show-pin-save-btn:hover {
  background-color: rgb(56, 10, 106);
}

#show-pin-save-btn.saved {
  background-color: black;
}

#show-pin-save-btn.saved:hover {
  background-color: black;
}

#pin-show-website {
  font-weight: 400;
  text-decoration: underline;
  align-items: center;
}

#pin-show-title {
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 2px;
  line-height: 1.3em;
  margin: 20px 0;
}

#pin-show-description {
  font-weight: 300;
  line-height: 1.2em;
}

#pin-show-creator-info {
  display: flex;
  align-items: center;
}

#create-pin-user-info .avatar {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  margin-right: 8px;
}

#pin-show-creator-initial {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: rgb(238, 238, 238);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

#saved-msg-container.save-pin-show {
  top: 0;
  left: calc(50% - 132px);
}