#create-pin-main-bg {
  width: 100vw;
  min-height: 100vh;
  background-color: rgb(238, 238, 238);
  display: flex;
  justify-content: center;
  position: relative;
}

#create-pin-content-bg {
  width: 890px;
  height: 100%;
  max-height: 900px;
  background-color: #fff;
  margin-top: 120px;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 35px;
  box-shadow: 0 0 10px 0 rgba(210, 210, 210, 0.591);
  display: flex;
  flex-direction: column;
  position: relative;
}

#create-pin-top-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#create-pin-top-btn-holder {
  width: 205px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.create-pin-drop-bg#board-options-menu {
  width: 200px;
  left: 245px;
}

#ellipsis-btn {
  font-size: 20px;
  width: 20px;
  height: 20px;
  padding: 8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-ellipsis {
  color: #696969;
}

#ellipsis-btn:hover {
  cursor: pointer;
  background-color: rgb(238, 238, 238);
}

#board-drop-save-btn-holder {
  width: 240px;
  height: 40px;
  display: flex;
}

#create-pin-board-dropdown-btn {
  width: 160px;
  height: 40px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: rgb(238, 238, 238);
}

#create-pin-save-btn {
  width: 70px;
  height: 40px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: rgb(85, 15, 159);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#create-pin-save-btn:hover {
  background-color: rgb(56, 10, 106);
}

#create-pin-bottom-content-holder {
  display: flex;
  flex-direction: row;
}

#create-pin-left-container {
  width: 270px;
  height: 100%;
  border-radius: 8px;
  border: 1px solid rgb(238, 238, 238);
  background-color: rgb(238, 238, 238);
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#create-pin-left-container.no-picture {
  border: 1px solid #CC0000;
  background-color: #ffe8e7;
}

#create-pin-upload-box {
  width: 255px;
  height: 100%;
  border-radius: 8px;
  border: 2px dashed #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#preview-pin-img {
  width: 255px;
}

#dropbox-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 515px;
}

#dropbox-text i {
 font-size: 22px;
 color: #444444;
}

#dropbox-text div {
  font-weight: 600;
  font-size: 14px;
  color: #444444;
  width: 150px;
  line-height: 1.2em;
  text-align: center;
  padding-top: 30px;
}

#dropbox-text.no-picture div {
  color: #CC0000;
}

#dropbox-text.no-picture i {
  font-size: 22px;
  color: #CC0000;
 }

#create-pin-right-container {
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 30px;
  position: relative;
}

#create-pin-title {
  font-weight: 600;
  font-size: 36px;
  border: 0;
  height: 48px;
  margin-top: 30px;
  box-sizing: border-box;
  box-shadow: rgba(142, 142, 142, 0.5) 0px 1px 0px 0px;
}

#create-pin-title:focus {
  outline: none;
  border-bottom: 2px solid #968bc6;
  box-shadow: none;
}

#create-pin-user-info {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 20px 0 20px 0;
  font-size: 14px;
  font-weight: 600;
}

.pin-initial-holder {
  font-size: 10px;
  background-color: rgb(238, 238, 238);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 8px;
  padding: 4px;
}

#create-pin-user-info > img {
  width: 48px;
  height: 48px;
  margin-right: 8px
}

#create-pin-initial > div {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: rgb(238, 238, 238);
  text-align: center;
}

#create-pin-description {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  min-height: 26px;
  border: 0;
  box-sizing: border-box;
  box-shadow: rgba(142, 142, 142, 0.5) 0px 1px 0px 0px;
  outline: none;
}

#create-pin-description:empty::before {
  content: "Tell everyone what your Pin is about";
  color: #999;
  font-weight: 300;
  cursor: text;
}

#create-pin-description:focus {
  outline: none;
  border-bottom: 2px solid #968bc6;
  box-shadow: none;
}

#cowboy {
  position: absolute;
  top: 276px;
  right: 28px;
  font-size: 28px;
}

#alt-btn-or-text {
  padding: 20px 0;
  min-height: 160px;
}

#add-alt-text-btn {
  box-sizing: border-box;
  background-color: rgb(238, 238, 238);
  cursor: pointer;
  height: 46px;
  width: 120px;
  border-radius: 24px;
  padding: 14px;
  border: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

#add-alt-text-btn:hover {
  background-color: rgb(215, 215, 215);
}

#alt-text-textbox {
  color: #333333;
  font-weight: 400;
  font-size: 16px;
  min-height: 26px;
  border: 0;
  box-sizing: border-box;
  box-shadow: rgba(142, 142, 142, 0.5) 0px 1px 0px 0px;
  outline: none;
  display: block;
}

#alt-text-textbox:empty::before {
  content: "Explain what people can see in the Pin";
  color: #999999;
  font-weight: 300;
}

#alt-text-textbox:focus {
  outline: none;
  border-bottom: 2px solid #968bc6;
  box-shadow: none;
}

#pin-website {
  font-weight: 300;
  font-size: 18px;
  border: 0;
  outline: none;
  height: 30px;
  box-sizing: border-box;
  box-shadow: rgba(142, 142, 142, 0.5) 0px 1px 0px 0px;
}

#pin-website:focus {
  outline: none;
  border-bottom: 2px solid #968bc6;
  box-shadow: none;
}

#relative-text-aligner {
  position: relative;
}

#saved-msg-container.save-pin {
  top: 120px;
}