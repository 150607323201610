.personal {
  padding-bottom: 20px;
}

#gender-options-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 468px;
  min-width: 208px;
  padding-top: 4px;
}

.gender-row {
  padding-bottom: 20px;
}

.gender-option {
  display: flex;
  flex-direction: row;
  margin-right: 35px;
}

.gender-option > input[type=radio] {
  transform: scale(1.9);
  cursor: pointer;
  accent-color: rgb(85, 15, 159);
}

.gender-option-text {
  margin-left: 10px;
  font-weight: 400;
  font-size: 15px;
}

.gender-input {
  width: 468px;
  min-width: 208px;
  margin-top: 14px;
}

.country-field {
  width: 468px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  -webkit-font-smoothing: antialiased;
  appearance: none;
}

.country-field:hover {
  cursor: pointer;
}

#country-select-holder {
  position: relative;
}

.country-dropbtn {
  position: absolute;
  right: 12px;
  top: 14px;
}

.country-dropbtn:hover {
  cursor: pointer;
}