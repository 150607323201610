.email {
  width: 468px;
  font-weight: 300;
  color: #262626;
}

.email.disabled {
  cursor: default;
}

.email.disabled:hover {
  border: 2px solid lightgray;
}

#password-field-holder {
  width: 468px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.password {
  width: 363px;
}

.password.disabled {
  cursor: default;
}

.password.disabled:hover {
  border: 2px solid lightgray;
}

.field-description.account {
  margin-bottom: 30px;
}

#change-pw-form-container {
  width: 428px;
  height: 445px;
  padding: 35px;
  display: flex;
  flex-direction: column;
}

.pw-header {
  display: flex;
  justify-content: space-around;
}

.pw-modal-text {
  width: 428px;
  margin-bottom: 30px;
}

.match-pw {
  color: #CC0000;
}

.valid-email {
  color: #CC0000;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0;
}

#pw-btn-holder {
  position: absolute;
  bottom: 35px;
  right: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.change-pw-btn {
  height: 40px;
  box-sizing: border-box;
  background-color: rgb(238, 238, 238);
  color: #111111;
  cursor: pointer;
  border-radius: 24px;
  padding: 16px 16px 16px 16px;
  border: 0;
  font-size: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  word-break: normal;
}

.change-pw-btn.disabled {
  cursor: not-allowed;
}

#delete-acc-holder {
  width: 468px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#delete-text-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  letter-spacing: 0.1px;
}

#delete-title {
  font-weight: 600;
}

#delete-description {
  font-weight: 400;
}

#confirm-delete-container {
  width: 428px;
  height: 430px;
  padding: 35px;
  display: flex;
  flex-direction: column;
}