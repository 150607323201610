#boards-index-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
  position: relative;
  width: 90vw;
}

#boards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

#plus-sign-holder {
  position: absolute;
  top: -72px;
  right: 10px;
  font-size: 24px;
  padding: 12px;
  border-radius: 100%;
}

#plus-sign-holder:hover {
  cursor: pointer;
  background-color: rgb(238, 238, 238);
}

#profile-create-dropdown {
  position: absolute;
  border-radius: 12px;
  right: 12px;
  top: -22px;
  width: 180px;
  height: 86px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 2px 5px 5px rgba(114, 114, 114, 0.1);
}

#create-text {
  font-size: 12px;
  font-weight: 300;
  padding: 4px 8px 8px 8px;
  border-radius: 8px;
}

.create-option {
  padding: 8px;
  border-radius: 8px;
  font-weight: 500;
}

.create-option:hover {
  background-color: rgb(238, 238, 238);
  cursor: pointer;
}

.filler-board {
  height: 231px;
  width: 236px;
  margin: 8px;
}

.board-item {
  min-height: 231px;
  margin: 8px;
  display: flex;
  flex-direction: column;
}

.board-item:hover {
  opacity: 0.8;
  cursor: zoom-in;
}

.board-item a:hover {
  cursor: zoom-in;
}

.board-img-holder {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.board-img-holder img {
  display: block;
  max-width: 157px;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.first-img {
  width: 157px;
  height: 157px;
  background-color: rgb(238, 238, 238);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  margin-right: 2px;
  overflow: hidden;
}

.first-img img {
  display: block;
  width: 157px;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.second-img-holder {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.secondary-img {
  width: 77px;
  height: 77.5px;
  background-color: rgb(238, 238, 238);
  border-top-right-radius: 16px;
  margin-bottom: 2px;
  overflow: hidden;
}

.secondary-img img {
  display: block;
  width: 77px;
  object-fit: cover;
  border-radius: 0 16px 0 0;
}

.tertiary-img {
  width: 77px;
  height: 77.5px;
  background-color: rgb(238, 238, 238);
  border-bottom-right-radius: 16px;
  overflow: hidden;
}

.tertiary-img img {
  display: block;
  width: 77px;
  object-fit: cover;
  border-radius: 0 0 16px 0;
}

.board-info {
  margin: 8px;
}

.board-name {
  font-size: 20px;
  font-weight: 600;
}

.board-pin-count {
  padding-top: 2px;
  font-size: 12px;
  font-weight: 300;
}

#create-board-form {
  width: 100%;
  height: 350px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

#x-button-holder.create-board-modal {
  display: none;
}

#create-board-title {
  letter-spacing: 0.01rem;
  font-size: 28px;
  font-weight: 600;
  padding: 40px 0;
}

#create-board-row-holder {
  margin-bottom: 50px;
}

#create-board-name-label {
  padding: 0 0 10px 10px;
}

.edit-pin-text-input.board-input {
  width: 450px;
}

#create-board-bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 30px;
  width: 450px;
  height: 70px;
  box-shadow: 0 -2px 8px rgba(0,0,0,0.12);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#create-board-modal-btn {
  padding: 14px;
  border-radius: 24px;
  background-color: rgb(238, 238, 238);
}

#create-board-modal-btn:hover {
  background-color: rgb(208, 208, 208);
  cursor: pointer;
}

#create-board-modal-btn.can-save {
  background-color: rgb(85, 15, 159);
  color: white;
}

#create-board-modal-btn.can-save:hover {
  background-color: rgb(56, 10, 106);
  color: white;
}