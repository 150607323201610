/* TODO Add site wide styles */

/* RESET CSS */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	text-decoration: none;
	color: #111111;
	word-break: break-word;
}

a, a:hover, a:focus, a:active {
	color: inherit;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body, textarea {
	line-height: 1;
	font-family: "system-ui", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: #111111;
	resize: none;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

#entire-page {
  overflow-y: scroll;
	overflow-x: hidden;
  height: 100vh;
  width: 100vw;
	position: absolute;
	z-index: -1;
}

#saved-msg-container {
  position: absolute;
  top: -30px;
	left: calc(50vw - 132px);
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.591);
  background-color: rgb(56, 10, 106);
	color: white;
	font-size: 22px;
	font-weight: 300;
  z-index: 10;
	opacity: 0;
  transition: transform 1.5s, opacity 1.5s;
}

#saved-msg-container.profile-save {
	left: calc(50% - 132px);
}

#saved-msg-container.saved {
  transform: translate(0, 70px);
	opacity: 0.9;
}