.loggednav-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 16px;
  flex-direction: row;
}

.logged-logo-holder {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 13px;
  cursor: pointer;
  border-radius: 100%;
  margin-right: 20px;
}

.logged-logo-holder:hover {
  background-color: rgb(238, 238, 238);
}

.logged-pindrip-icon {
  width: 24px;
  height: 24px;
}

.loggednav-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loggednav-btn {
  box-sizing: border-box;
  background-color: transparent;
  color: #111111;
  cursor: pointer;
  border-radius: 24px;
  padding: 14px;
  border: 0;
  font-size: 100%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loggednav-btn.active {
  background-color: black;
  color: white;
  outline: 2px solid black;
}

.active.initial-holder {
  color: #111111;
  outline: 2px solid black;
}

.loggednav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.right-icon-holder:hover {
  background-color: rgb(238, 238, 238);
  padding: 16px;
}

.right-icon-holder {
  width: 16px;
  height: 16px;
  font-size: 20px;
  padding: 16px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  width: 16px;
  height: 16px;
  font-size: 26px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initial-holder {
  font-size: 10px;
  font-weight: 600;
  background-color: rgb(238, 238, 238);
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 4px;
  margin: 8px;
}

.initial-holder > img {
  width: 24px;
  height: 24px;
}

.right-initial-icon-holder {
  padding: 4px;
}

.right-initial-icon-holder:hover {
  background-color: rgb(238, 238, 238);
  border-radius: 100%;
}

.right-drop-icon-holder {
  border-radius: 100%;
  padding: 4.5px;
  margin-left: 8px;
}

.right-drop-icon-holder i {
  font-size: 14px;
}

.right-drop-icon-holder:hover {
  cursor: pointer;
  background-color: rgb(238, 238, 238);
}