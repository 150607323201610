/* PAGE LAYOUT & SIDEBAR */
#edit-profile-main-container {
  width: 100vw;
  padding: 110px 20px 0 20px;
}

#edit-profile-sidebar-container {
  font-size: 16px;
  font-weight: 500;
  width: 190px;
  margin-bottom: 30px;
}

#edit-profile-side-plus-form-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 300px; 
  max-width: 900px;
  width: 95vw;
}

.edit-profile-sidebar-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
}

.edit-profile-sidebar-options {
  padding: 10px;
  color: #262626;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 27px;
}

#edit-underline {
  width: 100%;
  height: 3px;
  background-color: transparent;
  display: inline-block;
}

.active > .edit-profile-sidebar-options > #edit-underline {
  width: 100%;
  height: 3px;
  background-color: #262626;
  display: inline-block;
}

/* PROFILE FORM */
#edit-form-container {
  min-width: 300px;
  max-width: 500px;
  padding: 0 10px 80px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.edit-form-header-container {
  padding-top: 4px;
  padding-bottom: 36px;
}

.edit-form-title {
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 12px;
}

.edit-form-description {
  font-size: 16px;
  font-weight: 300;
}

.profile-errors {
  padding: 10px;
  background-color: #CC0000;
  border-radius: 12px;
  min-width: 300px;
  max-width: 500px;
  margin-bottom: 36px;
}

.profile-errors li {
  margin-bottom: 5px;
  color: white;
}

.profile-errors li:last-child {
  color: white;
  margin-bottom: 0;
}

.edit-form-field-row-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.edit-form-field-holder {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.edit-form-label {
  font-size: 12px;
  font-weight: 300;
  padding-bottom: 8px;
}

#edit-form-initial {
  font-weight: 600;
  font-size: 32px;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 100%;
  padding: 22px;
  background-color: #EEEEEE;
}

#edit-form-initial.avatar-holder {
  width: 76px;
  height: 76px;
  padding: 0;
}

.avatar-holder > img {
  height: 76px;
  width: 76px;
}

#change-avatar-btn {
  width: 85px;
  height: 40px;
  box-sizing: border-box;
  background-color: rgb(238, 238, 238);
  color: #111111;
  cursor: pointer;
  border-radius: 24px;
  padding: 16px 16px 16px 16px;
  border: 0;
  font-size: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  word-break: normal;
}

#change-avatar-btn:hover {
  background-color: rgb(215, 215, 215);
}

.edit-text-input-field {
  box-sizing: border-box;
  min-height: 48px;
  min-width: 100px;
  width: 230px;
  border-radius: 14px;
  border: 2px solid lightgray;
  padding: 13px;
  font-size: 16px;
}

.edit-text-input-field:hover {
  border: 2px solid #777777;
}

.edit-text-input-field:focus {
  outline: none;
  border: 2px solid #968bc6;
}

textarea.edit-text-input-field {
  padding-top: 10px;
  width: 468px;
  min-width: 208;
  height: 93px;
  line-height: 1.5em;
  resize: none;
  color: #2e2e2e;
}

.edit-text-input-field.pronouns {
  padding: 8px 13px;
  width: 468px;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
}

.pronoun-dropbtn {
  position: absolute;
  right: 12px;
}

.edit-text-input-field.clicked-pronouns {
  padding: 8px 13px;
  width: 468px;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 2px solid #968bc6;
}

#selected-pronouns {
  border-radius: 8px;
  background-color: #EEEEEE;
  padding: 8px 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#remove-pronouns {
  font-size: 12px;
  margin-left: 13px;
}

#remove-pronouns:hover {
  cursor: pointer;
}

#pronouns-list {
  position: absolute;
  margin-top: 6px;
  width: 448px;
  box-shadow: 0 0 8px #d4d4d4;
  padding: 10px;
  border-radius: 16px;
  z-index: 1;
  background-color: white;
}

.pronoun-option {
  padding: 8px 10px;
  font-weight: 400;
  border-radius: 8px;
}

.pronoun-option:hover {
  background-color: #EEEEEE;
  cursor: pointer;
}

.edit-form-field-row-holder.field-description {
  width: 468px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.1px;
  color:#444444;
  margin: 10px 0 0 0;
}

.edit-text-input-field.website {
  width: 468px;
  font-weight: 300;
  color: #2e2e2e;
}

.edit-text-input-field.website.disabled {
  border: 2px solid lightgray;
}

/* BOTTOM BAR */
#edit-page-bottom-bar {
  position: fixed;
  display: flex;
  align-items: center;
  height: 75px;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: white;
  box-shadow: 0 -1px 8px 0px #EEEEEE;
  z-index: 5;
}

#bottom-bar-align-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-width: 300px; 
  max-width: 900px;
  width: 95vw;
}

#bottom-bar-placeholder {
  height: 0.1px;
  width: 190px;
}

#edit-bottom-btn-holder {
  /* position: fixed;
  right: 220px; */
  min-width: 300px;
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.bottom-profile-btn {
  width: 70px;
  height: 40px;
  box-sizing: border-box;
  background-color: rgb(238, 238, 238);
  color: #444444;
  cursor: pointer;
  border-radius: 24px;
  padding: 22px 12px;
  border: 0;
  font-size: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.bottom-profile-btn.save-btn.disabled {
  background-color: rgb(238, 238, 238);
  color: #666;
  cursor: default;
}

.bottom-profile-btn.bottom-profile-btn.save-btn.disabled:hover {
  background-color: rgb(238, 238, 238);
  color: #666;
  cursor: default;
}

.bottom-profile-btn.save-btn {
  background-color: rgb(85, 15, 159);
  color: white;
}
.bottom-profile-btn.save-btn:hover {
  background-color: rgb(56, 10, 106);
}

.bottom-profile-btn:hover {
  background-color: rgb(215, 215, 215);
}


/* UPLOAD AVATAR MODAL */
#upload-avatar-container {
  padding: 35px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#upload-img-btn {
  height: 40px;
  box-sizing: border-box;
  background-color: rgb(85, 15, 159);
  color: #fff;
  cursor: pointer;
  border-radius: 24px;
  padding: 16px;
  font-size: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

#upload-img-btn:hover {
  background-color: rgb(56, 10, 106);
}

#upload-img-btn > img {
  height: 100px;
}

#chosen-avatar {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  margin-bottom: 36px;
}

.avatar-holder {
  font-size: 10px;
  font-weight: 300;
}

.avatar-holder > img {
  font-size: 0;
}